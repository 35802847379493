import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getUserTokenData } from '../../utils/get-user-token-data';

const env = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const alterpressApi = createApi({
    reducerPath: 'alterpressApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_URL,
        credentials: 'include',
        prepareHeaders: (headers) => {
            const tokenData = getUserTokenData();
            if (tokenData) {
                const token = tokenData.token;
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
    refetchOnFocus: env !== 'development',
});

export default alterpressApi;
