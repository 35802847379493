import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { RootState } from './store';
import { userApi } from './api/user';
import { cartApi } from './api/cart';
import { orderApi } from './api/order';
import { setCartId } from './slices/cart.slice';

export const listener = createListenerMiddleware();

listener.startListening({
    matcher: isAnyOf(
        userApi.endpoints.getUser.matchFulfilled,
        userApi.endpoints.loginUser.matchFulfilled
    ),
    effect: ({ payload }, { dispatch, getState }) => {
        const userCartId = payload.cart?.cartId;
        const currentCartId = (getState() as RootState).cart.cartId;
        if (currentCartId && currentCartId !== userCartId) {
            dispatch(cartApi.endpoints.assignCart.initiate(currentCartId));
        }
        if (!currentCartId && userCartId) {
            dispatch(setCartId(userCartId));
        }
    },
});

listener.startListening({
    matcher: isAnyOf(
        cartApi.endpoints.getCart.matchFulfilled,
        cartApi.endpoints.createCart.matchFulfilled,
        cartApi.endpoints.updateCart.matchFulfilled,
        cartApi.endpoints.assignCart.matchFulfilled
    ),
    effect: ({ payload }, { dispatch, getState }) => {
        const newOrder = (getState() as RootState).order;
        const mutation = {
            ...newOrder.mutation,
            items: payload.items.map((cartItem: { variantId: number; quantity: number }) => {
                return {
                    variantId: cartItem.variantId,
                    quantity: cartItem.quantity,
                };
            }),
        };
        dispatch(
            orderApi.endpoints.validateOrder.initiate(mutation, { fixedCacheKey: 'VALIDATE_ORDER' })
        );
    },
});

listener.startListening({
    matcher: orderApi.endpoints.createOrder.matchFulfilled,
    effect: ({ payload }) => {
        const redirectUrl = payload.payments[0]?.redirectUrl;
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    },
});
