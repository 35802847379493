import alterpressApi from './base';

import { TUserDataInitialValues } from '../../formik/user-data.form';
import { TRegisterFormInitialProps } from '../../formik/registration.form';
import { IPasswordResetData, IPasswordChangeData, IUserData } from '../../models/user.model';
import { TLoginFormInitialValues } from '../../formik/login.form';
import { INewsletterFormData } from '../../models/newsletter-form.model';

const userExpandParams = `expand=cart`;

export const userApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['User'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            loginUser: builder.mutation<IUserData, TLoginFormInitialValues>({
                query: (data) => ({
                    method: 'POST',
                    url: `login?${userExpandParams}`,
                    body: data,
                }),
            }),
            registerUser: builder.mutation<IUserData, TRegisterFormInitialProps>({
                query: (data) => ({
                    method: 'POST',
                    url: 'account/register',
                    body: data,
                }),
            }),
            getUser: builder.query<IUserData, void>({
                query: () => `account/user?${userExpandParams}`,
            }),
            verifyUser: builder.mutation<void, string>({
                query: (token) => `activate/${token}`,
            }),
            updateUser: builder.mutation<IUserData, TUserDataInitialValues>({
                query: (data) => ({
                    method: 'PATCH',
                    url: `account/user?${userExpandParams}`,
                    body: data,
                }),
            }),
            logoutUser: builder.mutation<void, void>({
                query: () => ({
                    method: 'POST',
                    url: 'logout',
                }),
            }),
            passwordRecovery: builder.mutation<void, void>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-reset-request',
                    body: data,
                }),
            }),
            passwordChange: builder.mutation<void, IPasswordChangeData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-change',
                    body: data,
                }),
            }),
            passwordReset: builder.mutation<void, IPasswordResetData>({
                query: (data) => ({
                    method: 'POST',
                    url: 'password-reset',
                    body: data,
                }),
            }),
            newsletter: builder.mutation<
                void,
                INewsletterFormData & { action: 'subscribe' | 'unsubscribe' }
            >({
                query: ({ action, ...data }) => ({
                    method: 'POST',
                    url: `newsletter/${action}`,
                    body: data,
                }),
                invalidatesTags: ['User'],
            }),
        }),
    });

export const {
    useLoginUserMutation,
    useRegisterUserMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useVerifyUserMutation,
    useLogoutUserMutation,
    usePasswordRecoveryMutation,
    usePasswordChangeMutation,
    usePasswordResetMutation,
    useNewsletterMutation,
} = userApi;
