import * as Yup from 'yup';

import IFormField from '../models/form-field.model';
import { IPaymentProvider } from '../models/payment-provider.model';
import useTranslations from '../hooks/use-translations';

export function getPaymentProvidersField(paymentProviders: IPaymentProvider[]): IFormField {
    const options: IFormField['options'] = paymentProviders.map((provider) => {
        return {
            label: provider.name,
            value: `${provider.providerId}`,
            enabled: true,
            imgUrl: provider.media[0]?.url,
        };
    });
    return {
        type: 'radio',
        name: 'payment.providerId',
        label: '',
        options: options,
    };
}

export function getOrderPaymentValidationSchema(
    t: ReturnType<typeof useTranslations<'OrderPaymentFields'>>
) {
    return Yup.object().shape({
        payment: Yup.object({
            providerId: Yup.string().required(t.fields.providerId.error.required),
        }),
    });
}

export interface IOrderPaymentFormValues {
    payment: {
        providerId: string;
    };
}

export const initialOrderPaymentValues: IOrderPaymentFormValues = {
    payment: {
        providerId: '',
    },
};

export function getSelectedPaymentProvider(
    selectedProviderId: number | string,
    providers: IPaymentProvider[]
) {
    return providers.find((provider) => provider.providerId.toString() === selectedProviderId);
}
