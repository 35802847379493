import alterpressApi from './base';

import { ICart, ICreateCartMutation, IUpdateCartMutation } from '../../models/cart.model';

const cartExpandParams = `expand=items,items.product,items.product.media,items.product.manufacturer,items.variant,items.variant.media,items.variant.sets,delivery,promotions`;

export const cartApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Cart'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCart: builder.query<ICart, ICart['cartId'] | undefined | null>({
                query: (cartId) => `carts/${cartId}?${cartExpandParams}`,
            }),
            createCart: builder.mutation<ICart, ICreateCartMutation>({
                query: ({ mutation }) => ({
                    url: `carts?${cartExpandParams}`,
                    method: 'POST',
                    body: mutation,
                }),
            }),
            updateCart: builder.mutation<ICart, IUpdateCartMutation>({
                query: (cartMutation) => ({
                    url: `carts/${cartMutation.cartId}?${cartExpandParams}`,
                    method: 'PATCH',
                    body: cartMutation.mutation,
                }),
            }),
            assignCart: builder.mutation<ICart, string | number>({
                query: (guestCartId) => ({
                    url: `carts/${guestCartId}/assign?${cartExpandParams}`,
                    method: 'POST',
                }),
            }),
        }),
    });

export const {
    useGetCartQuery,
    useCreateCartMutation,
    useUpdateCartMutation,
    useAssignCartMutation,
} = cartApi;
