import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { userApi } from '../api/user';
import { IUserData } from '../../models/user.model';
import { RootState } from '../store';

export interface IUserState {
    data: IUserData | null;
}

const initialState: IUserState = {
    data: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                userApi.endpoints.loginUser.matchFulfilled,
                userApi.endpoints.getUser.matchFulfilled,
                userApi.endpoints.registerUser.matchFulfilled,
                userApi.endpoints.updateUser.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                if (payload.token) {
                    state.data = payload;
                    localStorage.setItem('tokenData', JSON.stringify(payload.token));
                }
            }
        );
        builder.addMatcher(userApi.endpoints.logoutUser.matchFulfilled, () => {
            localStorage.removeItem('tokenData');
            window.location.reload();
        });
    },
});

export default userSlice.reducer;

export const selectUserState = (state: RootState) => state.user;
