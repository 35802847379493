import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { cartApi } from '../api/cart';
import { ICart } from '../../models/cart.model';
import { RootState } from '../store';

export interface ICartState {
    cartId: string | null;
    data: ICart | null;
    isAfterMutation: boolean;
}

const initialState: ICartState = {
    cartId: getCartIdFromLocalStorage(),
    data: null,
    isAfterMutation: false,
};

function getCartIdFromLocalStorage() {
    if (typeof window === 'undefined') return null;
    return localStorage.getItem('guestCartId');
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartId: (state, { payload }: PayloadAction<string>) => {
            state.cartId = payload;
        },
        setCartIsAfterMutation: (state, { payload }: PayloadAction<boolean>) => {
            state.isAfterMutation = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                cartApi.endpoints.getCart.matchFulfilled,
                cartApi.endpoints.updateCart.matchFulfilled,
                cartApi.endpoints.createCart.matchFulfilled,
                cartApi.endpoints.assignCart.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                state.cartId = payload.cartId;
                state.data = payload;
            }
        );
        builder.addMatcher(cartApi.endpoints.createCart.matchFulfilled, (_, action) => {
            const { payload, meta } = action;
            const isGuestCart = meta.arg.originalArgs.isGuestCart;
            if (isGuestCart) {
                localStorage.setItem('guestCartId', payload.cartId);
            }
        });
        builder.addMatcher(cartApi.endpoints.getCart.matchRejected, (state, action) => {
            const { payload } = action;
            if (payload?.status === 404) {
                state.cartId = null;
                state.data = null;
                state.isAfterMutation = false;
                localStorage.removeItem('guestCartId');
            }
        });
        builder.addMatcher(
            isAnyOf(
                cartApi.endpoints.updateCart.matchFulfilled,
                cartApi.endpoints.createCart.matchFulfilled
            ),
            (state) => {
                state.isAfterMutation = true;
            }
        );
        builder.addMatcher(cartApi.endpoints.assignCart.matchFulfilled, () => {
            localStorage.removeItem('guestCartId');
        });
    },
});

export default cartSlice.reducer;

export const { setCartId, setCartIsAfterMutation } = cartSlice.actions;

export const selectCartState = (state: RootState) => state.cart;
