import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import alterpressApi from './api';
import { listener } from './listener';
import orderSlice from './slices/order.slice';
import userSlice from './slices/user.slice';
import cartSlice from './slices/cart.slice';

const store = configureStore({
    reducer: {
        [alterpressApi.reducerPath]: alterpressApi.reducer,
        cart: cartSlice,
        order: orderSlice,
        user: userSlice,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(alterpressApi.middleware).prepend(listener.middleware);
    },
});
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
