import React, {useEffect, useLayoutEffect, useState} from 'react';
import { Script, GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';
import './src/styles/toastify.scss';
import store from './src/redux/store';

const queryClient = new QueryClient();

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
    const [loaded, setLoaded] = useState(false);

    useLayoutEffect(() => {
        if(loaded) {
            window.document.dispatchEvent(
                new Event('DOMContentLoaded', {
                    bubbles: true,
                    cancelable: true,
                })
            );
        }
    }, [loaded]);

    return (
        <Provider store={store}>
            <Script src={"https://kicket.com/embeddables/embedded-manager.js"} onLoad={() => setLoaded(true)} />
            <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
            <ToastContainer className="ap-toastify" />
        </Provider>
    );
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    pathname,
    prevRouterProps,
    routerProps,
}) => {
    const prevPathname = prevRouterProps?.location.pathname;
    const preventScroll = routerProps.location.state?.preventScroll;
    return !(prevPathname === pathname && preventScroll);
};
