import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { IOrderConsents, IOrderDraft, IValidateOrderMutation } from '../../models/order.model';
import {
    initialOrderAddressValues,
    IOrderAddressFormValues,
} from '../../formik/order-address.form';
import {
    initialOrderDeliveryValues,
    IOrderDeliveryFormValues,
} from '../../formik/order-delivery.form';
import {
    initialOrderPaymentValues,
    IOrderPaymentFormValues,
} from '../../formik/order-payment.form';
import { orderApi } from '../api/order';
import { cartApi } from '../api/cart';
import { RootState } from '../store';

export type TNewOrderStateStepMode = 'view' | 'hidden' | 'edit';

export interface INewOrderState {
    steps: {
        address: {
            mode: TNewOrderStateStepMode;
            isComplete: boolean;
            values: IOrderAddressFormValues;
        };
        deliveryProvider: {
            mode: TNewOrderStateStepMode;
            isComplete: boolean;
            values: IOrderDeliveryFormValues;
        };
        paymentProvider: {
            mode: TNewOrderStateStepMode;
            isComplete: boolean;
            values: IOrderPaymentFormValues;
        };
    };
    mutation: IValidateOrderMutation;
    draft: IOrderDraft | null;
}

const initialState: INewOrderState = {
    steps: {
        address: {
            mode: 'edit',
            isComplete: false,
            values: initialOrderAddressValues,
        },
        deliveryProvider: {
            mode: 'hidden',
            isComplete: false,
            values: initialOrderDeliveryValues,
        },
        paymentProvider: {
            mode: 'hidden',
            isComplete: false,
            values: initialOrderPaymentValues,
        },
    },
    mutation: {
        items: [],
        price: {},
        delivery: {},
        payment: {},
        consent: {
            firstConsent: false,
            secondConsent: false,
        },
        discountCodes: [],
    },
    draft: null,
};

export const newOrderSlice = createSlice({
    name: 'newOrder',
    initialState,
    reducers: {
        setNewOrderAddressValues: (state, { payload }: PayloadAction<IOrderAddressFormValues>) => {
            state.steps.address.values = payload;
            state.steps.address.mode = 'view';
            state.steps.address.isComplete = true;
            if (!state.steps.deliveryProvider.isComplete) {
                state.steps.deliveryProvider.mode = 'edit';
                return;
            }
            if (!state.steps.paymentProvider.isComplete) {
                state.steps.deliveryProvider.mode = 'edit';
                return;
            }
        },
        setNewOrderDeliveryProvider: (
            state,
            { payload }: PayloadAction<{ providerId: number; data?: Record<string, string> }>
        ) => {
            state.mutation.delivery.providerId = payload.providerId;
            state.steps.deliveryProvider.mode = 'view';
            state.steps.deliveryProvider.isComplete = true;
            if (payload.data) {
                state.mutation.delivery.data = payload.data;
            }
            if (!state.steps.paymentProvider.isComplete) {
                state.steps.deliveryProvider.mode = 'edit';
                return;
            }
        },
        setNewOrderPaymentProvider: (state, { payload }: PayloadAction<number>) => {
            state.mutation.payment.providerId = payload;
            state.steps.paymentProvider.isComplete = true;
            state.steps.paymentProvider.mode = 'view';
        },
        setNewOrderStepValues: (
            state,
            { payload }: PayloadAction<{ step: keyof INewOrderState['steps']; values: any }>
        ) => {
            const { step, values } = payload;
            if (step !== 'paymentProvider') {
                state.steps[step].mode = 'view';
            }
            state.steps[step].isComplete = true;
            state.steps[step].values = values;
            if (!state.steps.deliveryProvider.isComplete) {
                state.steps.deliveryProvider.mode = 'edit';
                return;
            }
            if (!state.steps.paymentProvider.isComplete) {
                state.steps.paymentProvider.mode = 'edit';
                return;
            }
        },
        setNewOrderMutation: (state, { payload }: PayloadAction<IValidateOrderMutation>) => {
            state.mutation = payload;
        },
        setNewOrderStepMode: (
            state,
            {
                payload,
            }: PayloadAction<{ mode: TNewOrderStateStepMode; step: keyof INewOrderState['steps'] }>
        ) => {
            state.steps[payload.step].mode = payload.mode;
        },
        setNewOrderUserConsent: (state, { payload }: PayloadAction<IOrderConsents>) => {
            state.mutation.consent = payload;
        },
        setNewOrderUserNote: (state, { payload }: PayloadAction<string>) => {
            state.mutation.userNote = payload;
        },
        setNewOrderDiscountCode: (state, { payload }: PayloadAction<string[]>) => {
            state.mutation.discountCodes = payload;
        },
        resetNewOrderStep: (state, { payload }: PayloadAction<keyof INewOrderState['steps']>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.steps[payload] = initialState.steps[payload];
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                cartApi.endpoints.getCart.matchFulfilled,
                cartApi.endpoints.createCart.matchFulfilled,
                cartApi.endpoints.updateCart.matchFulfilled,
                cartApi.endpoints.assignCart.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                state.mutation.items = payload.items.map((cartItem) => {
                    return {
                        variantId: cartItem.variantId,
                        quantity: cartItem.quantity,
                    };
                });
            }
        );
        builder.addMatcher(orderApi.endpoints.validateOrder.matchFulfilled, (state, action) => {
            const { payload } = action;
            state.draft = payload;
        });
    },
});

export default newOrderSlice.reducer;

export const {
    setNewOrderStepValues,
    setNewOrderAddressValues,
    setNewOrderStepMode,
    setNewOrderDeliveryProvider,
    setNewOrderPaymentProvider,
    setNewOrderMutation,
    setNewOrderUserNote,
    setNewOrderUserConsent,
    setNewOrderDiscountCode,
    resetNewOrderStep,
} = newOrderSlice.actions;

export const selectOrderState = (state: RootState) => state.order;
