import * as Yup from 'yup';
import { AnySchema } from 'yup';

import IFormField from '../models/form-field.model';
import { IAddress } from '../models/address.model';
import { IOption } from '../models/option.model';
import type useTranslations from '../hooks/use-translations';

export const getBuyerTypeOptions = (
    t: ReturnType<typeof useTranslations<'OrderAddressFields'>>
): IOption[] => [
    {
        label: t.fields.buyerType.private.label,
        value: 'private',
        enabled: true,
    },
    {
        label: t.fields.buyerType.company.label,
        value: 'company',
        enabled: true,
    },
];

export const getCompanyInvoiceFields = (
    t: ReturnType<typeof useTranslations<'OrderAddressFields'>>
): IFormField[] => [
    {
        name: 'payment.address.taxNumber',
        type: 'text',
        label: t.fields.taxNumber.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.taxNumber.error.required),
    },
    {
        name: 'payment.address.companyName',
        type: 'text',
        label: t.fields.companyName.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.companyName.error.required),
    },
    {
        name: 'payment.address.street',
        type: 'text',
        label: t.fields.street.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.street.error.required),
    },
    {
        name: 'payment.address.houseNumber',
        type: 'text',
        label: t.fields.houseNumber.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.houseNumber.error.required),
    },
    {
        name: 'payment.address.apartmentNumber',
        type: 'text',
        label: t.fields.apartmentNumber.label,
        initialValue: '',
        schema: Yup.string(),
    },
    {
        name: 'payment.address.zipCode',
        type: 'text',
        label: t.fields.zipCode.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.zipCode.error.required),
    },
    {
        name: 'payment.address.city',
        type: 'text',
        label: t.fields.city.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.city.error.required),
    },
];

export const getAddressFields = (t: ReturnType<typeof useTranslations<'OrderAddressFields'>>) => [
    {
        name: 'delivery.address.firstName',
        type: 'text',
        label: t.fields.firstName.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.firstName.error.required),
    },
    {
        name: 'delivery.address.lastName',
        type: 'text',
        label: t.fields.lastName.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.lastName.error.required),
    },
    {
        name: 'delivery.address.street',
        type: 'text',
        label: t.fields.street.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.street.error.required),
    },
    {
        name: 'delivery.address.houseNumber',
        type: 'text',
        label: t.fields.houseNumber.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.houseNumber.error.required),
    },
    {
        name: 'delivery.address.apartmentNumber',
        type: 'text',
        label: t.fields.apartmentNumber.label,
        initialValue: '',
        schema: Yup.string(),
    },
    {
        name: 'delivery.address.zipCode',
        type: 'text',
        label: t.fields.zipCode.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.zipCode.error.required),
    },
    {
        name: 'delivery.address.city',
        type: 'text',
        label: t.fields.city.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.city.error.required),
    },
    {
        name: 'delivery.address.phoneNumber',
        type: 'text',
        label: t.fields.phoneNumber.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.phoneNumber.error.required),
    },
    {
        name: 'delivery.address.email',
        type: 'email',
        label: t.fields.email.label,
        initialValue: '',
        schema: Yup.string()
            .email(t.fields.email.error.email)
            .required(t.fields.email.error.required),
    },
];

export const getInvoiceFields = (
    t: ReturnType<typeof useTranslations<'OrderAddressFields'>>
): IFormField[] => [
    {
        name: 'payment.address.firstName',
        type: 'text',
        label: t.fields.firstName.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.firstName.error.required),
    },
    {
        name: 'payment.address.lastName',
        type: 'text',
        label: t.fields.lastName.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.lastName.error.required),
    },
    {
        name: 'payment.address.street',
        type: 'text',
        label: t.fields.street.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.street.error.required),
    },
    {
        name: 'payment.address.houseNumber',
        type: 'text',
        label: t.fields.houseNumber.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.houseNumber.error.required),
    },
    {
        name: 'payment.address.apartmentNumber',
        type: 'text',
        label: t.fields.apartmentNumber.label,
        initialValue: '',
        schema: Yup.string(),
    },
    {
        name: 'payment.address.zipCode',
        type: 'text',
        label: t.fields.zipCode.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.zipCode.error.required),
    },
    {
        name: 'payment.address.city',
        type: 'text',
        label: t.fields.city.label,
        initialValue: '',
        schema: Yup.string().required(t.fields.city.error.required),
    },
];

export function getOrderAddressValidationSchema(
    buyerType: string,
    wantOtherInvoiceData: boolean,
    t: ReturnType<typeof useTranslations<'OrderAddressFields'>>
) {
    return Yup.object().shape({
        buyerType: Yup.string().required(),
        wantOtherInvoiceData: Yup.bool(),
        delivery: Yup.object({
            address: Yup.object(getPartialOrderAddressValidationSchema(getAddressFields(t))),
        }),
        payment: Yup.object({
            address:
                buyerType === 'company'
                    ? Yup.object(getPartialOrderAddressValidationSchema(getCompanyInvoiceFields(t)))
                    : wantOtherInvoiceData
                    ? Yup.object(getPartialOrderAddressValidationSchema(getInvoiceFields(t)))
                    : Yup.object(),
        }),
    });
}

export interface IOrderAddressFormValues {
    buyerType: 'private' | 'company';
    wantOtherInvoiceData: boolean;
    delivery: {
        address: IAddress;
    };
    payment: {
        address: IAddress;
    };
}

export const initialOrderAddressValues: IOrderAddressFormValues = {
    buyerType: 'private',
    wantOtherInvoiceData: false,
    delivery: {
        address: {
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            apartmentNumber: '',
            zipCode: '',
            city: '',
            phoneNumber: '',
            email: '',
            countryCode: 'PL',
        },
    },
    payment: {
        address: {
            firstName: '',
            lastName: '',
            companyName: '',
            taxNumber: '',
            street: '',
            houseNumber: '',
            apartmentNumber: '',
            zipCode: '',
            city: '',
            countryCode: 'PL',
        },
    },
};

function getPartialOrderAddressValidationSchema(fields: IFormField[]) {
    const schema: Record<string, AnySchema> = {};
    fields.forEach((field) => {
        if (!field.schema) return;
        const nameArr = field.name.split('.');
        const partialName = nameArr[nameArr.length - 1];
        schema[partialName] = field.schema;
    });
    return schema;
}
