exports.components = {
  "component---src-templates-about-tsx": () => import("./../../../src/.templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/.templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/.templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-product-listing-tsx": () => import("./../../../src/.templates/category-product-listing.tsx" /* webpackChunkName: "component---src-templates-category-product-listing-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/.templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-custom-tsx": () => import("./../../../src/.templates/custom.tsx" /* webpackChunkName: "component---src-templates-custom-tsx" */),
  "component---src-templates-error-404-tsx": () => import("./../../../src/.templates/error-404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/.templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/.templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/.templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-video-blog-tsx": () => import("./../../../src/.templates/video-blog.tsx" /* webpackChunkName: "component---src-templates-video-blog-tsx" */),
  "component---src-templates-video-category-tsx": () => import("./../../../src/.templates/video-category.tsx" /* webpackChunkName: "component---src-templates-video-category-tsx" */),
  "component---src-templates-video-post-tsx": () => import("./../../../src/.templates/video-post.tsx" /* webpackChunkName: "component---src-templates-video-post-tsx" */)
}

